<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        用户管理
      </template>
      <template #input>
        <!-- 输入框前文字样式：class="tag" -->
        <span class="tag">注册时间: </span>
        <!-- input框固定长度为240px -->
        <a-input placeholder="请选择注册时间" />
        <a-input placeholder="请输入关键字" />
        <!-- 选择框固定长度为193px 选择框后缀图标：slot="suffixIcon"-->
        <a-select default-value="lucy" @change="handleChange" >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="lucy">
            行大运
          </a-select-option>
          <a-select-option value="disabled" disabled>
            暴富
          </a-select-option>
        </a-select>
        <a-button class="all_btn_solid">搜索</a-button>
      </template>
    </HeaderBox>
  </div>
</template>

<script>
import HeaderBox from '@/components/HeaderBox.vue'
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {
    handleChange(value) {
      // console.log(`selected ${value}`);
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped></style>
